import React from 'react';

import { AuthContext } from '../AuthState';
import currentOffsetTop from '../../utils/currentOffsetTop';

import { IAction } from "../../types";

interface ISetErrors {
  message: string; 
  list: string[];
}

interface IState {
  breakpoint: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  errors: {
    message: string;
    list: string[];
    setErrors: (errors: ISetErrors) => void;
    clearErrors: () => void;
    report: (props: any) => void;
  }
  featuredEvent: object | any;
  history: any[];
  modalAlert: {
    height: number;
    iframeSrc: string;
    visible: boolean;
    setHeight: (height: number) => void;
    setSrc: (src: string) => void;
    toggle: () => void;
  };
  nav: {
    expanded: boolean;
    items: [];
    toggle: Function;
  };
  siteBanner: object | any;
  titleSuffix: (title: string) => string;
  w: number;
  h: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  scroll: {
    direction: string;
  }
}

declare global {
  interface Window {
    attachEvent(event: string, listener: EventListener): boolean;
    detachEvent(event: string, listener: EventListener): void;
  }
}

type Props = {
  children?: React.ReactNode;
}

const initialState: IState = {
  breakpoint: {
    xs: 575.98,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  errors: {
    message: '',
    list: [],
    setErrors: (errors: ISetErrors) => {},
    clearErrors: () => {},
    report: (props: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(props);
      }
      switch (true) {
        case props.code !== undefined && props.code === 'permission-denied':
          props.history.push('/404');
          break;
        default:
          break;
      }
    }
  },
  featuredEvent: {},
  history: [],
  modalAlert: {
    height: 300,
    iframeSrc: '',
    visible: false,
    setHeight: (height: number) => {},
    setSrc: (src: string) => {},
    toggle: () => {}
  },
  nav: {
    expanded: false,
    items: [],
    toggle: () => {}
  },
  siteBanner: {},
  titleSuffix: (title: string) => `${title} - Eposode`,
  w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  isMobile: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768) ? true : false,
  isTablet: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 768 && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 992) ? true : false,
  isDesktop: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 992) ? true : false,
  scroll: {
    direction: "down"
  }
}

export const PageContext = React.createContext(initialState);

const PageState: React.FC<Props> = (props) => {
  
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  const prevLocation: any = React.useRef<any>({});
  const { user } = React.useContext(AuthContext);
  
  React.useEffect(() => {

    const setPageSizing = ():void =>  {
      dispatch({
        type: "SET_PAGE_SIZING",
        payload: {
          width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
          isMobile: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768) ? true : false,
          isTablet: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 768 && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 992) ? true : false,
          isDesktop: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 992) ? true : false,
        }
      })
    }

    let lastScroll: number = 0;
    const scrollDirection = ():void => {
      if (currentOffsetTop() < lastScroll) {
        dispatch({
          type: "SET_SCROLL_DIRECTION",
          payload: {
            scroll: {
              direction: "up"
            }
          }
        })
      } else {
        dispatch({
          type: "SET_SCROLL_DIRECTION",
          payload: {
            scroll: {
              direction: "down"
            }
          }
        })
      }
      lastScroll = currentOffsetTop();
    }

    if (isFirstRender) {
      dispatch({
        type: "SET_PROPS",
        payload: {
          errors: {
            setErrors: (errors: ISetErrors) => {
              dispatch({
                type: "SET_ERRORS",
                payload: {
                  message: errors.message,
                  list: errors.list
                }
              })
            },
            clearErrors: () => {
              dispatch({
                type: "SET_ERRORS",
                payload: {
                  message: '',
                  list: []
                }
              })
            },
            report: (props: any) => {}
          },
          modalAlert: {
            setHeight: (height: number) => {
              dispatch({
                type: "SET_MODAL_HEIGHT",
                payload: { height }
              })
            },
            setSrc: (src: string) => {
              dispatch({
                type: "SET_MODAL_ISRC",
                payload: { iframeSrc: src }
              })
            },
            toggle: () => dispatch({ type: "TOGGLE_MODAL" })
          },
          nav: {
            toggle: (action: string | boolean) => {
              if (action === 'open' || action === true) {
                dispatch({
                  type: "TOGGLE_NAV",
                  payload: {
                    action: true
                  }
                })
              } else {
                dispatch({
                  type: "TOGGLE_NAV",
                  payload: {
                    action: false
                  }
                })
              }
            }
          }

        }
      });
      dispatch({
        type: "SET_HISTORY",
        payload: {
          history: prevLocation.current
        }
      })
      setIsFirstRender(false);
    }

    if(window.attachEvent) {
      window.attachEvent('onresize', setPageSizing);
      window.attachEvent('scroll', scrollDirection);
    } else if(window.addEventListener) {
        window.addEventListener('resize', setPageSizing, true);
        window.addEventListener('scroll', scrollDirection, true);
    } else {
        //The browser does not support Javascript event binding
    }

    return () => {
      if(window.detachEvent) {
        window.detachEvent('onresize', setPageSizing);
        window.detachEvent('scroll', scrollDirection);
      } else if(window.removeEventListener) {
          window.removeEventListener('resize', setPageSizing, true);
          window.removeEventListener('scroll', scrollDirection, true);
      } else {
          //The browser does not support Javascript event binding
      }
    }

  }, [user, isFirstRender, setIsFirstRender]);

  if (prevLocation.current !== window.location.pathname) {
    prevLocation.current = window.location.pathname;
    if (window.location.hash.length === 0) {
      window.scrollTo(0, 0);
    }
  }

  return <PageContext.Provider value={{ ...state }}>
    {props.children}
  </PageContext.Provider>;
}

const Reducer = (state: IState, action: IAction): IState => {
  const {type, payload} = action;
  switch (type) {
    case "SET_PAGE_STATE":
      return {
        ...state,
        featuredEvent: payload.featuredEvent,
        nav: {
          ...state.nav,
          items: payload.navItems
        },
        siteBanner: payload.siteBanner
      }
    case "SET_PROPS":
      return {
        ...state,
        errors: {
          ...state.errors,
          setErrors: payload.errors.setErrors,
          clearErrors: payload.errors.clearErrors,
          report: payload.errors.report
        },
        modalAlert: {
          ...state.modalAlert,
          setHeight: payload.modalAlert.setHeight,
          setSrc: payload.modalAlert.setSrc,
          toggle: payload.modalAlert.toggle,
        },
        nav: {
          ...state.nav,
          toggle: payload.nav.toggle
        }
      }
    case "SET_MODAL_HEIGHT":
      return {
        ...state,
        modalAlert: {
          ...state.modalAlert,
          height: payload.height
        }
      }
    case "SET_MODAL_ISRC":
      return {
        ...state,
        modalAlert: {
          ...state.modalAlert,
          iframeSrc: payload.iframeSrc
        }
      }
    case "TOGGLE_MODAL":
      return {
        ...state,
        modalAlert: {
          ...state.modalAlert,
          visible: !state.modalAlert.visible
        }
      }
    case "TOGGLE_NAV":
      return {
        ...state,
        nav: {
          ...state.nav,
          expanded: payload.action
        }
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: {
          ...state.errors,
          message: payload.message,
          list: payload.list
        }
      }
    case 'SET_HISTORY':
      return {
        ...state,
        history: [...state.history, payload.history]
      }
    case "SET_PAGE_SIZING":
      return {
        ...state,
        w: payload.width,
        h: payload.height,
        isMobile: payload.isMobile,
        isTablet: payload.isTablet,
        isDesktop: payload.isDesktop
      }
    case "SET_SCROLL_DIRECTION":
      return {
        ...state,
        scroll: {
          ...state.scroll,
          direction: payload.scroll.direction
        }
      }
    default:
      return state;
  }
};

export default PageState;