import React from 'react';
import firebase from '../../firebase';

import { IAction } from "../../types";

interface IState {
  user: object | undefined
  errors: object
}

type Props = {
  children: React.ReactNode
};

const initialState: IState = {
  user: undefined,
  errors: {}
}

// Context
export const AuthContext = React.createContext(initialState);

// State
const AuthState: React.FC<Props> = (props) => { 
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  React.useEffect(() => {
    if (!initialState.user) {
      firebase.auth()
        .signInAnonymously()
        .then(user => {
          // onAuthStateChanged will sign in an anonymous user
          dispatch({
              type: "SET_USER",
              payload: user.user
            });
        })
        .catch((err: any) => dispatch({ type: "SET_ERRORS", payload: err }));
    }
  }, []);

  return <AuthContext.Provider value={{ ...state}}>
    {props.children}
  </AuthContext.Provider>
;

}

// Reducer
const Reducer = (state: IState, action: IAction):IState => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {}
      }
    default:
      return state;
  }
}

export const db = firebase.firestore();
export const auth = firebase.auth();

export default AuthState;