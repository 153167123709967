import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { IDeviceSearch } from "../../types";

import './index.scss';
import CustomInitHits from '../../components/CustomInitHits';
import Layout from '../../partials/Layout';
import PageState, { PageContext } from '../../context/PageState';
import { db } from '../../context/AuthState';

const initialState: IDeviceSearch = {
  metadata: {},
  section: {},
  subfooter: {}
}

const staticState = require('./Static/State.json');
const staticDevicesState = require('./Static/Devices.json');

const DeviceSearch: React.FC<any> = (props) => {
  
  const { errors } = useContext(PageContext);
  const [state, setState] = useState<{ [key: string]: any; }>(initialState);

  useEffect(() => {

    // Get device page data
    db.doc("/pages/device-search")
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          setState(doc.data());
        }
      })
      .catch((err: any) => errors.report({ ...props, ...err }));

  }, [props, setState, errors]);

  document.body.className = "layout products devices";

  return <PageState>
    <Layout {...state}>
      <section className="ais-InstantSearch">
        <Container>
          <Row>
            <Col>
              <div id="initLayout">
                <CustomInitHits
                  staticState={staticState.section}
                  staticDevicesState={staticDevicesState}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </PageState>

}

export default DeviceSearch;
