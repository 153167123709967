import React, {useState} from 'react'
import './index.scss';

type Props = {
  className?: string;
  compatibility: string[];
  dark?: boolean;
  show?: number;
  small?: boolean;
}

const Pills: React.FC<Props> = props => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const comps = props.compatibility.slice(0, props.show || 3);
  const additional = props.compatibility.length - (props.show || 3);

  const Pill = (props: any) => <p
    className={`badge${props.className ? " " + props.className : ""}${props.dark ? " badge-dark" : ""}${props.small ? " small" : ""}`}
    onClick={props.onClick ? props.onClick : null}
  >
    {props.children}
  </p>;

  return <div className="badgesContainer">
    {!showAll && comps.map((comp: any, i: number) => <Pill {...props} key={i}>{comp}</Pill>)}
    {!showAll && (props.compatibility.length > 3) && <Pill onClick={() => setShowAll(true)} {...{...props, className: props.className + " showMore"}}>{additional}+</Pill>}
    {showAll && props.compatibility.map((comp: any, i: number) => <Pill {...props} key={i}>{comp}</Pill>)}
  </div>;
}

export default Pills;
