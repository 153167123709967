import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

// States
import AuthState from "./context/AuthState";

// Routes
import DevicesSearch from "./pages/DeviceSearch";

const Device = React.lazy(() => import("./pages/Device"));
const AccessorySearch = React.lazy(() => import("./pages/AccessorySearch"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

const App = () => {

  return <AuthState>
    <React.Suspense fallback="">
      <Switch>
        <Route exact path="/accessories" component={AccessorySearch} />
        {/* <Route exact path="/accessories/:slug" component={Accessory} /> */}
        <Route exact path="/devices/:devicePath" component={Device} />
        <Route exact path="/devices">
          <Redirect to="/" />
        </Route>
        <Route exact path="/" component={DevicesSearch} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </React.Suspense>
  </AuthState>;
}

export default App;