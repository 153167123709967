import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-snapshot';
import App from './app/App';

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);