import React from 'react';
import { PageContext } from '../context/PageState';
import { Modal, ModalBody, Button } from 'reactstrap';
import Header from './Header';
import Iframe from 'react-iframe';

import '../stylesheets/root.scss';

const Layout: React.FC = (props: any) => {
	
	const { modalAlert, titleSuffix } = React.useContext(PageContext);
	const [isStalled, setIsStalled] = React.useState<boolean>(true);
	
	React.useEffect(() => {

		/**
		 * Title
		 * Payload is passed through the Layout Component
		 * Cloud Funciton only specific page title but React Helmet updates title via HTML5 AP
		*/

		if (props.metadata && props.metadata.title) {
			document.title = titleSuffix(props.metadata.title);
		}

		setTimeout(() => setIsStalled(false), 1100);

		// Listen for Pardot postMessage
		window.addEventListener('message', (message) => {
			if (/^https?:\/\/go.ipcmobile.com/.test(message.origin) && typeof message.data == "string") {		
				const payload = JSON.parse(message.data);

				switch (payload.action) {
					case 'set_form_height':
						modalAlert.setHeight(payload.height);
						break;
					default:
					// no action taken
				}
			}
		});

	}, [props, modalAlert, isStalled, titleSuffix, setIsStalled]);

	const externalCloseBtn = <Button close onClick={modalAlert.toggle}>
		<span>
			<img
				className="icon"
				src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/close-x-white.svg"
				alt="Close X contact us form icon"
			/>
		</span>
	</Button>;

	return <React.Fragment>
		<Header
			metadata={props.metadata} 
			dbLoaded={props.dbLoaded}
		/>
		<main>
			{props.children}
		</main>
		<Modal
			isOpen={modalAlert.visible} 
			toggle={modalAlert.toggle}
			centered={true}
			external={externalCloseBtn}
			wrapClassName="form-modal"
		>
			<div className="modal-content">
				<ModalBody>
					<Iframe
						url={modalAlert.iframeSrc}
						width="100%"
						height={modalAlert.height + 20 + 'px'}
						display={(!modalAlert.visible) ? "none" : "block"}
						frameBorder={0}
					/>
				</ModalBody>
			</div>
		</Modal>

	</React.Fragment>
};

export default Layout;
