import React from 'react';
import { Link } from 'react-router-dom';
import Pills from './Pills';
import { PageContext } from '../context/PageState';
import { IAction } from "../types";

interface Props {
  staticState: any;
  staticDevicesState: any;
}

interface IState {
  sections: any;
  devices: any;
}

const initialState: IState = {
    sections: [],
    devices: {}
};

const CustomInitHits: React.FC<Props> = props => {

  const [state, dispatch] = React.useReducer(Reducer, initialState);
  const { modalAlert } = React.useContext(PageContext);

  React.useEffect(() => {

    dispatch({
        type: "SET_SECTIONS",
        payload: {
            sections: props.staticState
        }
    });

    dispatch({
      type: "SET_DEVICES",
      payload: {
        devices: props.staticDevicesState
      }
    });

  }, [props.staticDevicesState, props.staticState]);

  const keys: string[] = ['quantum', 'linea-pro', 'imperea', 'infinea-mpos', 'infinea-tab', 'omni-one', 'mobile-printers'];

  return <React.Fragment>
    {keys.map((sec: any, h: number) => {

      if (state.devices[sec] !== undefined && state.devices[sec].length > 0) {

        if (state.sections[sec] !== undefined) {
          return <section
            key={h}
            className={`${sec}-sec`}
          >
            <h3>{state.sections[sec].headline && state.sections[sec].headline}</h3>
            <p>{state.sections[sec].description && state.sections[sec].description}</p>

            <div className="display-card-wrapper">
              {state.devices[sec].map((hit: any, j: number) => <div className="display-card" key={(hit.objectID !== undefined) ? hit.objectID : j }>
                {hit.cta_type === "link" && <Link
                  to={hit.slug}
                  target={typeof hit.slug === 'object' ? '_blank' : '_self'}
                >
                  <img
                    className='img-fluid'
                    src={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_300,b_rgb:ececec/v1/${hit.images[0].src}`}
                    srcSet={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_600,b_rgb:ececec/v1/${hit.images[0].src}, 2x`}
                    alt={`Thumbnail of ${hit.title}`}
                  />
                </Link>}
                {hit.cta_type === "form" && <button
                  data-toggle="modal"
                  data-target="#iframeModal"
                  onClick={() => {
                    modalAlert.setSrc(`//go.ipcmobile.com/l/668843/2018-12-18/73d?Product_Interest=${hit.title}`);
                    modalAlert.toggle();
                  }}
                >
                  <img
                    className='img-fluid'
                    src={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_300,b_rgb:ececec/v1/${hit.images[0].src}`}
                    srcSet={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,w_600,b_rgb:ececec/v1/${hit.images[0].src}, 2x`}
                    alt={`Thumbnail of ${hit.title}`}
                  />
                </button>}
                <div className='display-card-content'>
                  <div className='compatible devices'>
                    <Pills
                      className="low-em-bg"
                      compatibility={hit.compatibility}
                      small
                    />
                  </div>
                  <h4 className='h5 font-weight-bold'>
                    {hit.title && hit.title}
                  </h4>
                  <p className="small">
                    {hit.blurb.default && hit.blurb.default}
                  </p>
                  {hit.cta_type === "link" && <Link
                    to={hit.slug}
                    className='btn btn-primary btn-w100'
                    target={typeof hit.slug === 'object' ? '_blank' : '_self'}
                  >
                    Learn More
                  <img className='btn-icon right-arrow'
                      src='https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg'
                      alt={`Learn more about ${hit.title} arrow`}
                    />
                  </Link>}
                  {hit.cta_type === "form" && <button
                    data-toggle="modal"
                    data-target="#iframeModal"
                    className='btn btn-primary btn-w100'
                    onClick={() => {
                      modalAlert.setSrc(`//go.ipcmobile.com/l/668843/2018-12-18/73d?Product_Interest=${hit.title}`);
                      modalAlert.toggle();
                    }}
                  >
                    Learn More
                  <img className='btn-icon right-arrow'
                      src='https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-arrow-white.svg'
                      alt={`Learn more about ${hit.title} arrow`}
                    />
                  </button>}
                </div>
              </div>)}
            </div>


          </section>
        } else {
          return ''
        }
      } else {
        return '';
      }

    })}
  </React.Fragment>
}

const Reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SECTIONS':
      return {
        ...state,
        sections: payload.sections
      }
    case 'SET_DEVICES':
      return {
        ...state,
        devices: payload.devices
      }
    default:
      return state;
  }
}

export default CustomInitHits;