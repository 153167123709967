import React from 'react';
import { Link } from 'react-router-dom';

import "./index.scss";

const Header: React.FC<any> = (props) => {
	
	let heading1: string;

	if (props.dbLoaded) {	
		// heading1
		if (
			props.metadata.heading1 !== undefined && 
			props.metadata.heading1.length > 0
		) {
			heading1 = props.metadata.heading1;
		} else if ( 
			props.metadata.title !== undefined && 
			props.metadata.title.length > 0
		) {
			heading1 = props.metadata.title
		} else {
			heading1 = 'Eposode';
		}
	} else {
		heading1 = 'Eposode';
	}

	return <header>
		<div className="header-container">
			<div className="header-wrap container-fluid">
				<div className="row">
					<div className="col">
						<div className="site-logo">
							<Link to="/">
								<div className="img-wrap">
									<img
										src="https://res.cloudinary.com/ipcmobile/image/upload/v1656622536/partners/eposode-logo-inverted.svg"
										alt="Eposde Data Solutions logo" />
								</div>
								<h1 className="d-none">
									{heading1}
								</h1>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>;
};

export default Header;
