import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyBQY6XyPoSyDC1qN3pKfNJovfXMmn7KMhI",
  authDomain: "eposode-site-ipcmobile.firebaseapp.com",
  databaseURL: "https://eposode-site-ipcmobile.firebaseio.com",
  projectId: "eposode-site-ipcmobile",
  storageBucket: "eposode-site-ipcmobile.appspot.com",
  messagingSenderId: "436546734162",
  appId: "1:436546734162:web:8e476616393a6d82c9378b"
};

firebase.initializeApp(config);

export default firebase;